.about-us {
    margin-bottom: 3rem;
    margin-top: -120px;

    &__box {
        background-color: $gray-200;

        padding: 6rem;

        p {
            font-size: 1.25rem;

            color: $secondary;

            text-align: justify;

            line-height: 2rem;
        }
    }
}

.our-team {
    margin-bottom: 3rem;

    h2 {
        font-weight: bold;
        font-size: 2rem;
        color: $primary;

        text-align: center;

        padding-bottom: 1rem;
        margin-bottom: 4rem;

        position: relative;

        &::after {
            content: "";
            width: 150px;
            height: 4px;

            background-color: $primary;

            border-radius: 2px;

            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__member {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        // max-width: 800px;
        margin: auto;

        &--column {
            flex-direction: column;
            text-align: center;
        }
    }

    &__member-image {
        max-width: 200px;
        width: 200px;
        height: 200px;

        margin: auto;

        flex-shrink: 0;

        border: 1px solid $dark;
        border-radius: 50%;

        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: auto;

            position: relative;

            z-index: 9;
        }
    }

    &__member-info {
        h3,
        h4 {
            font-weight: bold;
            color: $secondary;
        }

        p {
            margin-bottom: 0;
        }

        &--column {
            margin: 1rem auto 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .about-us {
        &__box {
            padding: 4rem 2rem;
        }
    }

    .our-team {
        margin-bottom: 3rem;

        h2 {
            font-weight: bold;
            font-size: 2rem;
            color: $primary;

            text-align: center;

            padding-bottom: 1rem;
            margin-bottom: 2rem;

            position: relative;

            &::after {
                content: "";
                width: 150px;
                height: 4px;

                background-color: $primary;

                border-radius: 2px;

                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__member {
            display: block;

            text-align: center;
        }

        &__member-info {
            margin-top: 1rem;
            margin-left: 0;
        }
    }
}
